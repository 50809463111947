<template>
  <div class="profileConnections">
    <div class="profileConnections__title">
      {{ $t('profileConnections.title') }}
    </div>

    <p class="profileConnections__body">
      {{ $t('profileConnections.body') }}
    </p>

    <div class="profileConnections__connections">
      <div
          class="profileConnections__connection"
          v-if="champ"
      >
        <div class="profileConnections__connectionLogoWrapper">
          <img
              v-bind:src="require('@/assets/profile/logo-trackers.png')"
              class="profileConnections__connectionLogo"
          />
        </div>

        <div class="profileConnections__connectionInfoWrapper">
          <div class="profileConnections__connectionInfo">
            <div class="profileConnections__connectionInfoTitle">
              {{ $t('champStatusCard.title') }}
            </div>

            <p class="profileConnections__connectionInfoBody">
              {{ $t('champStatusCard.body') }}
            </p>
          </div>

          <div class="profileConnections__connectionFooter">
            <div class="profileConnections__connectionFooterLink">
              <base-button
                  v-if="!member.integrations.champ"
                  class="button"
                  modifiers="primary small"
                  v-on:click.prevent="onShowChampConnectModal"
              >
                {{ $t('champStatusCard.connectButtonLabel') }}
              </base-button>

              <div
                  v-if="member.integrations.champ"
                  class="profileConnections__connected"
              >
                <font-awesome-icon
                    icon="check"
                    class="profileConnections__connectedIcon"
                />

                {{ $t('champStatusCard.connectionStatus') }}

                <div
                    class="profileConnections__connectedDisconnect"
                    v-on:click="onShowChampDisconnectModal"
                >
                  {{ $t('champStatusCard.disconnectButtonLabel') }}
                </div>
              </div>
            </div>
            <div class="profileConnections__connectionFooterLink">
              <base-button
                  modifiers="secondary small"
                  class="button"
                  v-on:click="onChampMoreInfoClick"
              >
                {{ $t('medisanaStatusCard.moreInfoLinkLabel') }}
              </base-button>
            </div>
          </div>
        </div>
      </div>

      <div class="profileConnections__connection">
        <div class="profileConnections__connectionLogoWrapper">
          <img
              v-bind:src="require('@/assets/profile/logo-medisana.png')"
              class="profileConnections__connectionLogo"
          />
        </div>

        <div class="profileConnections__connectionInfoWrapper">
          <div class="profileConnections__connectionInfo">
            <div class="profileConnections__connectionInfoTitle">
              {{ $t('medisanaStatusCard.title') }}
            </div>

            <p class="profileConnections__connectionInfoBody">
              {{ $t('medisanaStatusCard.body') }}
            </p>
          </div>

          <div class="profileConnections__connectionFooter">
            <div class="profileConnections__connectionFooterLink">
              <base-button
                  v-if="!member.integrations.medisana"
                  class="button"
                  modifiers="primary small"
                  v-on:click.prevent="onShowMedisanaConnectModal"
              >
                {{ $t('medisanaStatusCard.connectButtonLabel') }}
              </base-button>

              <div
                  v-if="member.integrations.medisana"
                  class="profileConnections__connected"
              >
                <font-awesome-icon
                    icon="check"
                    class="profileConnections__connectedIcon"
                />

                {{ $t('medisanaStatusCard.connectionStatus') }}

                <div
                    class="profileConnections__connectedDisconnect"
                    v-on:click="onShowMedisanaDisconnectModal"
                >
                  {{ $t('medisanaStatusCard.disconnectButtonLabel') }}
                </div>
              </div>
            </div>
            <div class="profileConnections__connectionFooterLink">
              <base-button
                  modifiers="secondary small"
                  class=""
                  href="https://www.medisana.nl/VitaDock-App-2-0.html?lang=3"
                  target="_blank"
              >
                {{ $t('medisanaStatusCard.moreInfoLinkLabel') }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent, markRaw} from 'vue';
import {mapState} from 'vuex';
import BaseButton from '@/components/BaseButton';
import {ModalBus} from '@/eventBus';

const MedisanaModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/profile/MedisanaModal' /* webpackChunkName: "medisanaModal" */),
}));

const MedisanaDisconnectModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/profile/MedisanaDisconnectModal' /* webpackChunkName: "medisanaDisconnectModal" */),
}));

const ChampModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/profile/ChampModal' /* webpackChunkName: "champModal" */),
}));

const ChampInfoModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/profile/ChampInfoModal' /* webpackChunkName: "champInfoModal" */),
}));

const ChampDisconnectModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/profile/ChampDisconnectModal' /* webpackChunkName: "champDisconnectModal" */),
}));

export default {
  components: {
    BaseButton,
  },

  computed: {
    ...mapState('member', ['member']),
    ...mapState('experimental', ['champ']),
  },

  methods: {
    onShowMedisanaConnectModal() {
      ModalBus.emit('open', {
        component: MedisanaModal,
      });
    },

    onShowMedisanaDisconnectModal() {
      ModalBus.emit('open', {
        component: MedisanaDisconnectModal,
      });
    },

    onShowChampConnectModal() {
      ModalBus.emit('open', {
        component: ChampModal,
      });
    },

    onShowChampDisconnectModal() {
      ModalBus.emit('open', {
        component: ChampDisconnectModal,
      });
    },

    onChampMoreInfoClick() {
      this.$emit('close');

      ModalBus.emit('open', {
        component: ChampInfoModal,
        modifiers: 'champ',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileConnections__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profileConnections__body {
  @include small;
  margin: 0 0 rem(24px) 0;
}

.profileConnections__connection {
  display: flex;
  padding: rem(20px) rem(16px);
  border-radius: 10px;
  border: 5px solid rgba($color-grey--light, 0.2);

  &:not(:first-child) {
    margin-top: rem(20px);
  }

  @media all and (max-width: 450px) {
    flex-direction: column;
    text-align: center;

    .profileConnections__connectionFooter {
      display: inline-flex;
    }
  }
}

.profileConnections__connectionLogoWrapper {
  flex: 0 0 80px;
}

.profileConnections__connectionLogo {
  margin: 0 rem(16px) 0 0;
  width: 90px;
  height: 90px;
  object-fit: cover;
}

.profileConnections__connectionInfoWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
}

.profileConnections__connectionInfo {
  //flex: 1 1;
  margin: 0 rem(16px) rem(16px) 0;
  min-width: 200px;
}

.profileConnections__connectionInfoTitle {
  @include paragraph--bold;
  margin: 0 0 rem(11px) 0;
}

.profileConnections__connectionInfoBody {
  @include note;
}

.profileConnections__connectionFooter {
  display: flex;

  .profileConnections__connectionFooterLink:not(:last-child) {
    margin: 0 rem(24px) 0 0;
    flex: 0 0;
  }
}

.profileConnections__connected {
  @include label;
}

.profileConnections__connectedIcon {
  margin: 0 rem(3px) 0 0;
  font-size: rem(18px);
  color: $color-mint--dark;
}

.profileConnections__connectedDisconnect {
  @include note;
  margin: 0 0 0 rem(25px);
  text-decoration: underline;
  color: $color-grey;
  cursor: pointer;
}
</style>
